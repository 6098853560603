import React from 'react';

import { withRouter } from 'react-router-dom'

import firebase from '../firebase'

import { Col, Layout, Row } from "antd";

import LoginForm from "../components/LoginForm";

class Login extends React.Component {
  componentDidMount() {
    if (firebase.getCurrentUser()) {
      this.props.history.replace('/dashboard');
    }
  }

  render() {
    return (
      <Layout className="login-page">
        <Row>
          <Col xs={{ span: 24 }} lg={{ span: 16 }}>
            <img className="d-none" src={require("../../images/cities.jpg")} alt="background" />
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 8 }}>
            <div className="login-wrapper">
              <div className="login-logo">
                <img src={require("../../images/logo.png")} alt="logo" />
              </div>
              <LoginForm onSuccess={(status) => {
                if (status) {
                  this.props.history.replace('/dashboard')
                }
              }} />
            </div>
          </Col>
        </Row>
      </Layout>
    );
  }
};

export default withRouter(Login);
