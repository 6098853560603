import React from 'react';

import { withRouter } from 'react-router-dom'

import firebase from '../firebase'

import { Col, Layout, Row, Modal } from "antd";

import ForgotPasswordForm from "../components/ForgotPasswordForm";

class ForgotPassword extends React.Component {
  componentDidMount() {
    if (firebase.getCurrentUser()) {
      this.props.history.replace('/');
    }
  }

  render() {
    return (
      <Layout className="login-page">
        <Row>
          <Col xs={{ span: 24 }} lg={{ span: 16 }}>
            <img className="d-none" src={require("../../images/cities.jpg")} alt="background" />
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 8 }}>
            <div className="login-wrapper">
              <div className="login-logo">
                <img src={require("../../images/logo.png")} alt="logo" />
              </div>
              <ForgotPasswordForm onSuccess={(status) => {
                if (status) {
                  Modal.info({
                    title: 'Success',
                    content: "Please check your email for the instructions.",
                    onOk() {},
                  });

                  this.props.history.replace('/login')
                }
              }} />
            </div>
          </Col>
        </Row>
      </Layout>
    );
  }
};

export default withRouter(ForgotPassword);
